export default {
	formUseLand: {
					flag:{
						buildLandFlagHasStage: 0,//['0:涉及建设用地', '1:不涉及建设用地', '2:存量建设用地']
						buildLandFlagNotes: '' //备注
					},
					source: {	// 建设用地来源
						buildLandSourceType: 0,
						buildLandUniType: 0, //	['0:是', '1:否'],
						buildLandSourceTotalCost: 0,
						buildLandSourceSpecialCost: 0,
						buildLandSourceCompanyCost: 0, //	'县国资公司资金',
						buildLandSourceSourceCost: 0, //	'县本级财政资金',
						buildLandSourceOtherCost: 0, //	'其他来源资金',
						buildLandSourcePlanFile: [],
						buildLandSourceImageFile: [],
						buildLandSourcePaymentFile: [],
						buildLandSourceTransferFile: [], // 
						buildLandSourceHandoverFile: [], //
						buildLandSourceSummaryFile: [], //
					},
					nature:{
						buildLandNatureHasForestry: 0,
						buildLandNatureReportFile: [],
						buildLandNatureAuditFile: [],
						buildLandNatureAuditTime: '',
						buildLandNatureMapCost: 0,
						buildLandNatureMapSpecialCost: 0,
						buildLandNatureMapCompanyCost: 0,
						buildLandNatureMapSourceCost: 0,
						buildLandNatureMapOtherCost: 0,
						buildLandNatureMapTicket: [],
						buildLandNatureMapPayment: [],
						buildLandNatureApprovalCost: 0,
						buildLandNatureApprovalSpecialCost: 0,
						buildLandNatureApprovalCompanyCost: 0,
						buildLandNatureApprovalSourceCost: 0,
						buildLandNatureApprovalOtherCost: 0,
						buildLandNatureApprovalTicket: [],
						buildLandNatureApprovalPayment: []
					},
					approval: {	// 土地报批
						// fileList: [],
						// buildLandApprovalCostSource: 0,
						buildLandApprovalSpecialCost: 0,
						buildLandApprovalCompanyCost: 0,
						buildLandApprovalSourceCost: 0,
						buildLandApprovalOtherCost: 0,
						buildLandApprovalCost: undefined, //	'报批费用金额',
						buildLandApprovalTicket: [],	// 发票
						buildLandApprovalPaymentFile: [] // 支付凭证
					},
					gain: { // 取得方式
						buildLandGainType: 0,
						buildLandGainTotalCost: 0, //	'转让总金额',
						buildLandGainSpecialCost: 0, //	'上级专项资金',
						buildLandGainCompanyCost: 0, //	'县国资公司资金',
						buildLandGainSourceCost: 0, //	'县本级财政资金',
						buildLandGainOtherCost: 0, //	'其他来源资金',
						buildLandGainConfirmFile: [], // 土地成交确认书
						buildLandGainContractFile: [], // 土地出让/转让合同,
						buildLandGainChangeFile: [],	// '国有建设用地使用权划拨决定书'
						buildLandGainTicketFile: []	,// '发票URL',
						buildLandGainPaymentFile: [],	//  '支付凭证URL',
						// fileList: []
					},
					register: {	//  前置办理不动产登记证
						buildLandRegisterStatus: 0,
						buildLandRegisterTotalCost: 0, //	'办证费用总金额',
						buildLandRegisterSpecialCost: 0, //	'上级专项资金',
						buildLandRegisterCompanyCost: 0, //	'县国资公司资金',
						buildLandRegisterSourceCost: 0, //	'县本级财政资金',
						buildLandRegisterOtherCost: 0, //	'其他来源资金',
						buildLandRegisterPermitFile: [], // '用地规划许可证'
						buildLandRegisterRegisterFile: [],	// '不动产登记证',
						buildLandRegisterTicketFile: [],	// '发票URL'
						buildLandRegisterPaymentFile: [],	// '支付凭证URL'
						// fileList: []
					},
					speed: {	//  三通一平支出
						buildLandSpeedStatus: 0,
						buildLandSpeed:[{
							unitName: '',
							notice: [],
							contract: [],
							summary: [],
							cost: undefined,
							completedPercent: undefined,
							payPercent: 0.7,
							paymentPercentage: undefined,
							payment: [
								{
									type: 0,
									amount: 0,
									source: 0,
									scheduleFile: [],
									applyFile: [],
									ticket: [],
									voucher: [],
									auditCost: 0,
									auditReport: []
								}
							]
						}],
						// buildLandSpeedUnitName: '',
						// buildLandSpeedTotalCost: undefined, //	'合同金额',
						// buildLandSpeedCompletedPercent: undefined, //	'工程完成量',
						// buildLandSpeedPayPercent: 0.7, //	'项目办待定付款比例',
						// buildLandSpeedContractFile: [],	// '施工合同'
						// buildLandSpeedNoticeFile: [],	// '中标通知书',
						// buildLandSpeedSummaryFile: [],	// '会议纪要或文件'
						// // fileList: [],	
						// paymentPercentage: undefined,	// '工程进度百分比'
						// buildLandSpeedPaymentFile:[{
						//     amount:undefined,
						// 	type: 0,
						// 	auditCost: 0, //	'审计金额',
						//     source:undefined,
						// 	voucher: [], //	'支付凭证',
						// 	applyFile: [] ,//	'请款审批表',
						// 	scheduleFile: [],	// '工程进度报表',
						// 	ticket: [],	//'发票',
						// 	auditReport: [],	// '审计报告'
						// }],
					},
					costAudit: {	// 三通一平审计
						buildLandCostAuditStatus: 0,
						buildLandCostAuditTotalCost:  undefined, //	'合同金额',
						buildLandCostAuditContractFile: [],	//'施工合同',
						buildLandCostAuditNoticeFile: [],	// '中标通知书/成交确认书',
						buildLandCostAuditSummaryFile: [],	// '会议纪要或文件',
						buildLandCostAuditCompanyName: '',
						// fileList: [],
						buildLandCostAuditPaymentFile:[{
						    amount:undefined,
						    source:undefined,
							voucher: [],	// '支付凭证',
							applyFile: [],	// '请款审批表',
							ticket: [],	//	'发票',
							// fileList: []
						}]
					},
					result: {	// 污染状况调查
						buildLandResultStatus: 0,
						buildLandResultTotalCost: undefined, //	'合同金额',
						buildLandResultContractFile: [],	// '施工合同',
						buildLandResultNoticeFile: [], //	'中标通知书',
						buildLandResultSummaryFile: [], //	'会议纪要或文件',
						buildLandResultSurveyFile: [],	// '调查成功文件',
						buildLandResultUnitName: '',
						// fileList: [],
						buildLandResultPaymentFile:[{
						    amount:undefined,
						    source:undefined,
							type: 0,
							applyFile: [],	// '请款审批表',
							ticket: [],	// '发票',
							voucher: [],	// '支付凭证',
							// fileList: [],
						}]
					},
					// property: 1,
					// payCheck: 1,
					// isSurvey: 1,
					// buildLandCostAuditStatus: 1,
	                // payList:[{
	                //     amount:'',
	                //     source:''
	                // }],
	                // surveyList:[{
	                //     amount:'',
	                //     source:''
	                // }],
	                // buildLandCostAuditPaymentFile:[{
	                //     amount:'',
	                //     source:''
	                // }]
	},
	//建设用地储备
	formApproval: {
		important: {
			projectApprovalIsImportant: 1,
			projectApprovalIsPlan: 0
		},
		//  2.资金来源
		source: {
		    projectApprovalSourceSpecialCost: 0, //	'上级专项资金',
		    projectApprovalSourceFileNum: '', //	'指标文件号',
		    projectApprovalSourceCompanyCost: 0, //	'县国资公司资金',
		    projectApprovalSourceSourceCost: 0, //	'县本级财政资金',
		    projectApprovalSourceOtherCost: 0, //	'其他来源资金',
			// projectApprovalOtherCost: 0, //	'其他来源资金',
		    projectApprovalSourceGovInvestPlan: [], //	'政府投资计划文件',
		    projectApprovalSourceSpecialFile: [], //	'专项资金文件',
		    projectApprovalSourceSummaryFile: [], //	'县政府常务会纪要文件',
		    projectApprovalSourceCountyFile: [], //	'县领导审批文件',
		    projectApprovalSourceOtherFile: [] //	'其他审批文件'
		},
		review: {	//  3.项目建议书批复文件
		    projectApprovalReviewStatus: 0,
		    projectApprovalReviewTotalCost:undefined, //	'批复概算金额',
		    projectApprovalReviewReviewFile: [], //	'立项批复文件',
		    projectApprovalReviewReviewBook: [], //	'项目建议书'
		},
		//  4.项目选址意见书
		opinion: {
			projectApprovalOpinionFlag: 0,
		    projectApprovalOpinionFile: [] //	'用地选址意见书'
		},
		//  5.科研批复
		feasibility: {
		    projectApprovalHasFeasibility: 0,//	['0：有可研','1：无可研', '1：不需要',],
		    projectApprovalFeasibilityFile: [],//	'可研批复文件'
		},
		//  6.用地许可证、工程规划许可证
		permit: {
		    projectApprovalPermitLandFile: [], //	'用地许可证',
		    projectApprovalPermitProjectFIle: [], //	'工程规划许可证'
		},
		//  7.社会最风险评估
		risk: {
		    projectApprovalHasRisk: 0, //	['0：有备案表','1：无备案表','1：不需要',],
		    projectApprovalRiskFile: [], //	'社会风险稳定评估备案表'
		},
		//  8.初步设计批复
		design: {
		    projectApprovalHasDesign: 0, //	['0：有初设批复','1：无初设批复','1：不需要',],
		    projectApprovalDesignFile: [], //	'初步设计批复'
		},
		//  9.环境影响评估
		env: {
		    projectApprovalHasEnv: 0, //	['0：有环评','1：无环评','1：不需要',],
		    projectApprovalEnvApprovalFile: [], //	'环评批复文件',
		    projectApprovalEnvRecordFile: [], //	'环评备案文件'
		},
		//  10.水土保持方案
		keep: {
		    projectApprovalHasKeep: 0, //	['0：有水保方案','1：无水保方案','1：不需要',],
		    projectApprovalKeepFile: [], //	'水保方案'
		},
		//  11.防洪影响评价
		evaluate: {
		    projectApprovalHasEvaluate: 0, //	['0：有防洪评价','1：无防洪评价','1：不需要',],
		    projectApprovalEvaluateFile: [], //	'防洪评价文件',
		},
		//  12.节能审批
		ec: {
		    projectApprovalHasEc: 0, //	['0：有节能审批','1：无节能审批','1：不需要',],
		    projectApprovalEcFIle: [], //	'节能审批文件'
		},
		//  13.消防审批
		fire: {
		    projectApprovalHasFire: 0, //	['0：有消防审批','1：无消防审批','1：不需要',],
		    projectApprovalFireFile: [], //	'消防审批意见文书' ？？
		},
		//  14.人防审批
		pad: {
		    projectApprovalHasPad: 0, //	['0：有人防审批','1：无人防审批','1：不需要',],
		    projectApprovalFireFile: [], //	'人防审批意见文书' ？？
		},
		//  15.预算评审
		budget: {
		    projectApprovalBudgetReport: [], //	'预算评审报告'
		},
		//  16.班子会会议纪要
		meeting: {
		    projectApprovalMeetingSelected: [], //	['0：项目立项','1：聘请可研编制','2：聘请施工图设计','3：聘请测绘','4：聘请地勘','5：聘请预算编制','6：聘请招标代理公司','7：其他',],
		    projectApprovalMeetingSummary: [], //	'会议纪要'
			projectApprovalMeetingContent: ''
		},
		// isFinish: 1,
		// isFeasibility: 1,
		// isRisk: 1,
		// isApproval: 1,
		// isEstimate: 1,
		// isProtect: 1,
		// isFlood: 1,
		// isPower: 1,
		// isFire: 1,
		// isDefence: 1,
	    // summary:[],
		//  17.本阶段聘请中介的中标通知书(或电子卖场或中介超市成交通知书) 、合同、费用支付情况
		speed: {
		    //  17-1.可研编制
		    feasibility: {
		        projectApprovalSpeedFeasibilityNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedFeasibilityContract:  [], //	'合同',
		        projectApprovalSpeedFeasibilityCost: undefined, //	'合同金额',
		        projectApprovalSpeedFeasibilityPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	'发票',
		                voucher: [], //	'支付凭证'
		            },
		        ]
		    },
		    //  17-2.施工图设计
		    designer: {
		        projectApprovalSpeedDesignerNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedDesignerContract: [], //	'合同',
		        projectApprovalSpeedDesignerCost: undefined, //	'合同金额',
		        projectApprovalSpeedDesignerPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	'发票',
		                voucher:  [], //'支付凭证'
		            }
		        ]
		    },
		    // //  17-3.图审
		    checker: {
		        projectApprovalSpeedCheckerNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedCheckerContract:  [], //	'合同',
		        projectApprovalSpeedCheckerCost: undefined, //	'合同金额',
		        projectApprovalSpeedCheckerPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	'发票',
		                voucher: [], //	'支付凭证'
		            }
		        ]
		    },
		    // //  17-4.绘测
		    surveyor: {
		        projectApprovalSpeedSurveyorNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedSurveyorContract: [], //	'合同',
		        projectApprovalSpeedSurveyorCost: undefined, //	'合同金额',
		        projectApprovalSpeedSurveyorPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	'发票',
		                voucher: [], //	'支付凭证'
		            }
		        ]
		    },
		    // //  17-5.地勘
		    geological: {
		        projectApprovalSpeedGeologicalNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedGeologicalContract: [], //	'合同',
		        projectApprovalSpeedGeologicalCost: undefined, //	'合同金额',
		        projectApprovalSpeedGeologicalPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	 '发票',
		                voucher: [], //	'支付凭证'
		            }
		        ]
		    },
		    // // 17-6.预算编制
		    budgeting: {
		        projectApprovalSpeedBudgetingNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedBudgetingContract: [], //	'合同',
		        projectApprovalSpeedBudgetingCost: undefined, //	'合同金额',
		        projectApprovalSpeedBudgetingPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	'发票',
		                voucher: [], //	'支付凭证'
		            }
		        ]
		    },
		    // //  17-7.预算评审
		    budget: {
		        projectApprovalSpeedBudgetNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedBudgetContract: [], //	'合同',
		        projectApprovalSpeedBudgetCost: undefined, //	'合同金额',
		        projectApprovalSpeedBudgetPayment: [
		            {
		                amount: undefined, //	'支付金额',
		                source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	'发票',
		                voucher: [], //	'支付凭证'
		            }
		        ]
		    },
		    // //  17-8.招标代理
		    agent: {
		        projectApprovalSpeedAgentNotice: [], //	'中标通知书或成交确认书',
		        projectApprovalSpeedAgentContract: [], //	''合同',
		        projectApprovalSpeedAgentCost: undefined, //	''合同金额',
		        projectApprovalSpeedAgentPayment: [
		            {
		                amount: undefined, //	''支付金额',
		                source: undefined, //	'['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                ticket: [], //	''发票',
		                voucher: [], //	''支付凭证'
		            }
		        ]
		    },
		    // //  17-9.其他中介服务
		    other: {
		        projectApprovalSpeedOther: [
		            {
		                service: '', //	'服务名称',
		                notice: [], //	'中标通知书或成交确认书',
		                contract: [], //	'合同',
		                cost: undefined, //	'合同金额',
		                payment: [
		                    {
		                        amount: undefined, //	'支付金额',
		                        source:undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
		                        ticket: [], //	'发票',
		                        voucher: [], //	'支付凭证'
		                    },
							// {amount: undefined,source:undefined,ticket: [],voucher: []}
		                ]
		            },
					// {service: '',notice: [],contract: [],cost: undefined,payment: [{amount: undefined,source:undefined,ticket: [],voucher: []}]}
		        ]
		    }
		},
	    // employFeasibilityList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employDesignerList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employDrawPriceList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employSurveyList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employGroundList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employBudgetList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employAgentList:[{
	    //     amount:'',
	    //     source:''
	    // }],
	    // employMediumList:[{
	    //     amount:'',
	    //     source:''
	    // }]
	},
	//项目立项
	inviteTenders:{
				//  1.县政府常务会议纪要
				summary: {
				    inviteTendersSummaryType: 1, //	 ['1.EPC项目' , '0.非EPC项目'],
				    inviteTendersSummaryFile: [], //	'会议纪要'
				},
				//  2.班子会会议纪要
				meeting: {
				    inviteTendersMeetingSummary: [], //	'会议纪要'
				},
				//  3.中标信息
				success: {
				    inviteTendersSuccessCompany: '', // '中标公司名称',
				    inviteTendersSuccessPrice: 0, //	'工程中标价',
				    inviteTendersSuccessNoticeFile: [], //	'中标通知书/交易确认书',
				    inviteTendersSuccessContract: [], //	'中标合同',
					inviteTendersSuccessWay: null     //    '招标方式'
				},
				//  4.监理信息
				watcher: {
				    inviteTendersHasWatcher: 0, //	['0：有监理','1：有监理(包含在全过程咨询服务中)','2：无监理','3：不需要',],
				    inviteTendersWatcherCompany: '',//	'监理公司名称',
				    inviteTendersWatcherNoticeFile: [], //	'通知书或成交确认书',
				    inviteTendersWatcherSummary: [], //	'会议纪要',
				    inviteTendersWatcherContract: [], //	'合同',
				    inviteTendersWatcherPrice: undefined, //	'合同金额',
				    inviteTendersWatcherPayment: [
				        {
				            amount: undefined, //	'付款金额',
				            source: 0, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				            applyFile: [], //	'请款审批表',
				            ticket: [], //	'发票',
				            voucher: [], //	'支付凭证',
				        }
				    ],
				},
				//  5.跟踪审计信息
				flowable: {
				    inviteTendersHasFlowable: 0, //	['0：有跟踪审计','1：无跟踪审计','2：不需要'],
				    inviteTendersFlowableCompany: '', //	'跟踪审计名称',
				    inviteTendersFlowableNoticeFile: [], //	'通知书或成交确认书',
				    inviteTendersFlowableSummary: [], //	'会议纪要',
				    inviteTendersFlowableContract: [], //	'合同',
				    inviteTendersFlowablePrice: undefined, //	'合同金额',
				    inviteTendersFlowablePayment: [
				        {
				            amount: undefined, //	'付款金额',
				            source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				            applyFile: [], //	'请款审批表',
				            ticket: [], //	'发票',
				            voucher: [], //	'支付凭证',
				        }
				    ],
				},
				//  6.全过程咨询信息
				saf: {
				    inviteTendersHasSaf: 0, //	['0：有全过程咨询','1：有全过程咨询(含监理服务)','2：无全过程咨询','3：不需要',],
				    inviteTendersSafCompany: '', //	'全过程咨询公司名称',
				    inviteTendersSafNoticeFile: [], //	'通知书或成交确认书',
				    inviteTendersSafSummary: [], //	'会议纪要',
				    inviteTendersSafContract: [], //	'合同',
				    inviteTendersSafPrice: undefined, //	'合同金额',
				    inviteTendersSafPayment: [
				        {
				            amount:undefined, //	'付款金额',
				            source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				            applyFile: [], //	'请款审批表',
				            ticket: [], //	'发票',
				            voucher: [], //	'支付凭证',
				        }
				    ],
				},
				// isEPC: 1,
				// issupervise: 1,
				// isAudit: 1,
				// isConsult: 1,
	//             superviseList:[{
	//                 amount:'',
	//                 source:''
	//             }],
	//             surveyList:[{
	//                 amount:'',
	//                 source:''
	//             }],
	//             auditList:[{
	//                 amount:'',
	//                 source:''
	//             }],
	//             consultList:[{
	//                 amount:'',
	//                 source:''
	//             }],
	},
	//项目招标
	construction:{
				//  1 许可证或开工报告
				permit: {
				    constructionHasPermit: 0, //	['0：有许可证或开工报告','1：无许可证或开工报告'],
				    constructionPermitBuildFile: [], //	'施工许可证',
				    constructionPermitStartReport: [], //	'开工报告',
				},
				//  2 项目变更
				change: {
				    constructionHasChange: 0, //	['0：有变更','1：无变更'],
				    constructionChangeRequest: [], //	'增量申请文件',
				    constructionChangeResponse: [], //	'增量申请文件'
				},
				//  3 项目设备询价
				inquiry: {
				    constructionHasInquiry: 0, //	['0：有设备询价','1：有设备询价(无询价公司)','2：无设备询价'],
				    constructionInquiryCompany: '', //	'询价公司名称',
				    constructionInquiryNoticeFile: [], //	'中标通知书或成交确认书',
				    constructionInquirySummary: [], //	'会议纪要',
				    constructionInquiryContract: [], //	'合同',
				    constructionInquiry: [], //	'同意询价批复',
				    constructionInquiryResult: [], //	'询价结果',
				    constructionInquiryCost: undefined, //	'合同金额',
				    constructionInquiryPayment: [
				        {
				            amount: undefined, //	'付款金额',
				            source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				            applyFile: [], //	'请款审批表',
				            ticket: [], //	'发票',
				            voucher: [], //	'支付凭证',
				        },
						// {amount: undefined,source: undefined,applyFile: [],ticket: [],voucher: []}
				    ]
				},
				//  工程进度
				schedule: {
				    constructionSchedulePercent: undefined, //	'工程完成量/元',
				    constructionSchedulePayPercent: 0.7, //	'项目办付款比例', ??
				},
				//  工程款付款
				payment: {
				    constructionPayment: [
				        {
				            type: 0, //	['0.预付款' ,'1.进度款' , '2.结算款', '3.尾款'],
				            amount: undefined, //	'付款金额',
				            source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				            applyFile: [], //	'请款审批表',
				            ticket:  [], //	'发票',
				            voucher:  [], //	'支付凭证',
							progress: [], //	'工程进度报表'
							audit: [], //	'审计报告'
							auditCost: 0,
				        },
						// {type: 0,amount: undefined,source: undefined,applyFile: [],ticket:  [],voucher:  [],progress: [],audit: []}
				    ]
				},
				//  审计
				audit: {
				    constructionHasAudit: 1, //	['0：有审计','1：有审计(无询价公司)','2：无审计','3：不需要'],
				    constructionAuditCompany: '',//	'审计公司名称',
				    constructionAuditNoticeFile: [], //	'中标通知书或成交确认书',
				    constructionAuditSummary: [], //	'会议纪要',
				    constructionAuditContract: [], //	'合同',
				    constructionAuditCost: undefined, //	'合同金额',
				    constructionAuditPayment: [
				    {
				        amount: undefined, //	'付款金额',
				        source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				        applyFile: [], //	'请款审批表',
				        ticket: [], //	'发票',
				        voucher: [], //	'支付凭证',
				    }
				    ]
				},
				// isAllow: 1,
				// isChange: 1,
				// isConsult: 1,
				// isPay: 1,
				// isModify: 1,
				// isAudit: 1,
	//             consultList:[{
	//                 amount:'',
	//                 source:''
	//             }],
	//             payList:[{
	//                 amount:'',
	//                 source:''
	//             }],
	//             auditList:[{
	//                 amount:'',
	//                 source:''
	//             }]
	},
	//项目施工
	completion:{
				//  1.避雷检查
				lpi: {
				    completionHasLpi: 0, //	['0：有避雷检查', '1：无避雷检查','2：不需要'],
				    completionLpiReport: [], //	'避雷检查报告',
				},
				//  2.消防验收报告
				fire: {
				    completionHasFire: 0, //	['0：有消防验收', '1：无消防验收','2：不需要'],
				    completionFireReport: [], //	'消防验收报告'
				},
				//  3.建设规划验收报告
				build: {
				    completionHasBuild: 0, //	['0：有建设规划验收', '1：无建设规划验收','2：不需要'],
				    completionBuildReport: [], //	'建设规划验收报告'
				},
				//  4.环评验收报告
				eia: {
				    completionHasEia: 0, //	['0：有环评验收', '1：无环评验收','2：不需要'],
				    completionEiaReport: [], //	'.环评验收报告'
				},
				//  5.水土保持验收报告
				keep: {
				    completionHasKeep: 0, //	['0：有水保验收', '1：无水保验收','2：不需要'],
				    completionKeepReport: [], //	'水土保持验收报告'
				},
				//  6.竣工验收报告
				completed: {
				    completionReport: [], //	交工验收报告,
				    completionCompletedReport: [], //	'.竣工验收报告',
				},
				//  7.竣工验收备案表
				filing: {
				    completionHasFiling: 0, //	['0：有竣工验收备案', '1：无竣工验收备案','2：不需要'],
				    completionFilingReport: [], //	'.竣工验收备案',
				},
				//  8.本阶聘请第三方报告
				other: {
					completionHasOrder: 0,
				    completionOther: [
				        {
				            costType: 0, //	['0.避雷检查', '1.消防验收', '2.建设规划验收', '3.环评验收水', '4.保验收交', '5.竣工验收', '6.竣工备案', '7.其他',],
				            costName: '', //	'费用名称',
				            company: '', //	'第三方公司名称',
				            summary: [], //	'聘请第三方会议纪要',
				            procedures: [], //	'聘请第三方的确认手续',
				            contract: [], //	'聘请第三方的合同',
				            cost: undefined, //	'合同总金额',
				            payment: [
				                {
				                    amount: undefined, //	'付款金额',
				                    source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				                    ticket: [], //	'发票',
				                    voucher: [], //	'支付凭证'
				                },
								// {amount: undefined,source: undefined,ticket: [],voucher: []}
				            ]
				        },
						// {costType: 0,costName: '',company: '',summary: [],procedures: [],contract: [],cost: undefined,payment: [{amount: undefined,source: undefined,ticket: [],voucher: []}]}
				    ]
				},
				
				//  9.项目财务决算报告
				accounts: {
				    completionAccountsCost: undefined, //	'决算金额',
				    completionAccountsReport: [], //	'项目财务决算报告'
				},
	},//竣工验收
}